import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button } from '@mui/material';
// components
import Page from '../../components/Page';

import Iconify from '../../components/Iconify';
import { AdminDashboardCount, getUsers } from '../../services';
import { AppWidgetSummary } from '../../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {

  var signdonecount = []
  var SignNotDoneCount = []
  var LinkExpired = []
  const theme = useTheme();
  const [showModal, setShowModal] = useState(true);
  const [showCount, setShowCount] = useState("");
  const [showCoun1t, setShowCount1] = useState("");


  const adminID = JSON.parse(localStorage.getItem('admin')).id;
  const SuperAdminToken = JSON.parse(localStorage.getItem('admin')).accessToken;


  const countDashboard = () => {

    const data = {
      "adminid": adminID,
    }

    const response = AdminDashboardCount(data, SuperAdminToken)
    response.then((val) => {
      if (val) {
        setShowCount(val)
      }
    })




  }


  const GetClients = async () => {

    const response = await getUsers(SuperAdminToken);
 //   console.log("hellowordl", response);

    if (response) {

      const arr = response.filter((item) => {

        if (item.sign_status === 1) {
          // return setShowCount1(item)
          return signdonecount.push(item)

        }
        else if (new Date(item.linkexpires) > new Date() && item.sign_status === 0) {
          return SignNotDoneCount.push(item)
          // return setShowCount1(item)

        }
        else if (new Date(item.linkexpires) < new Date() && item.sign_status === 0) {
          return LinkExpired.push(item)
          // return setShowCount1(item)
        }

      })


      const count = {
        'SignNotDoneCount': SignNotDoneCount.length,
        "LinkExpired": LinkExpired.length,
        "signdonecount": signdonecount.length, 
        "all": signdonecount.length
      }
      setShowCount1(count)


    }
  }
  
  // -----------------------

  // -----------------------









  useEffect(() => {
    countDashboard();
    GetClients();
    const timeout = setTimeout(() => {
      setShowModal(false);
    }, 5000);

    // Cleanup the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);




  return (
    <>
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <div className='d-flex mb-5'>
          <Typography variant="h4" className='flex-grow-1'  >
            Admin Dashboard
          </Typography>
          <Button className="mx-1" style={{ color: '#fff' }} variant="contained" component={RouterLink} to="/admin/addclient" startIcon={<Iconify icon="line-md:plus" />}>
            Add Client
          </Button>

        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist?cl=nan"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="All Users" total={showCount.alluser} icon={'fa-solid:users'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist?active=1"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="E-Sign Done" total={showCoun1t.signdonecount} color="info" icon={'fa6-solid:user-large'} />
            </NavLink>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist?inactive=0"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="E-Sign Pending" total={showCoun1t.SignNotDoneCount} color="warning" icon={'fa6-solid:user-large-slash'} />
            </NavLink>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <NavLink
              to={"/admin/clientlist?linkexpiry=2"}
              style={{ textDecoration: 'none' }}
            >
              <AppWidgetSummary title="Link Expired" total={showCoun1t.LinkExpired} color="warning" icon={'mdi:email-resend-outline'} />
            </NavLink>
          </Grid>
          {/* 
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Bug Reports" total={234} color="error" icon={'ant-design:bug-filled'} />
          </Grid> */}

        </Grid>
      </Container>
     
    </Page>
    <div  className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none',zIndex:'9999'}}>
        <div className="modal-dialog">
          <div className="modal-content" style={{border:'2px solid green'}}>
            <div className="modal-header  border-0 p-0">
            <div className='w-100' style={{ background: '#000', height: '100px' }}>
          <a href="#"><img src="/images/e-sign-aadhaar.png" className=" m-auto logo-img" alt="e-sign" style={{ position: 'relative', top: '5px',width:'200px' }} /></a>
        </div>
              
              
            </div>
            <div className="modal-body text-center">
            <h5 className="modal-title">Dear Sir, </h5>
              <p> you are informed that you should download and keep the signature document of all the clients because the document will be automatically deleted after<strong> 90 days.</strong></p>
             
            </div>
          </div>
        </div>
      </div>

      {/* Backdrop for the modal */}
      <div className={`modal-backdrop fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}></div>
   </>
  );
}
