import { format, formatDistanceToNow } from 'date-fns';

const dateTime = require('node-datetime');


// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm:ss');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'MM/dd/yyyy hh:mm:ss');
}
export function f_time(date) {
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export const dateFormate = (date) =>{
  const dt = dateTime.create(date);
  const ccdate = dt.format('Y-m-d');
  return ccdate
} 
export const dateFormatePlusThirty = (date) =>{
console.log("hello" ,new Date(date));
  // var date = new Date(); // Now
  // var date = "2022-12-26 10:06:27"
  date.setDate( date + 30); // Set now + 30 days as the new date
  console.log(date);
  return date
  
  // const dt = dateTime.create(date);
  // const ccdate = dt.format('Y-m-d');
  // return ccdate
} 
