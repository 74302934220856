
import React, { useEffect, useState,useRef } from 'react'
import { Link as RouterLink, NavLink, useParams, useLocation } from 'react-router-dom';
import { Card, Table, Stack, Avatar, Button, Checkbox, TableRow, Alert, TableBody, TableCell, Container, Typography, Tooltip, TableContainer, TablePagination, } from '@mui/material';
import { Icon } from '@iconify/react';
// dialog Box
import DatePicker from "react-datepicker";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ExportToExcel from "../../../utils/ExportToExport";

import DataTable from "../../../utils/DataTable";
import { fDateTime } from '../../../utils/formatTime';
import Iconify from '../../../components/Iconify';
import { AdminTrasnectionHistory } from '../../../services';
import AlertMessages from '../../../utils/AlertMessages';

const TransecectionHistory = () => {
    const location = useLocation()
    const ref = useRef();
    const ref1 = useRef();

    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]);
    const [fullname, setfullname] = useState('');
    const [refresh, setrefresh] = useState(false)
    const [alertMsg, setAlertMsg] = useState(false)
    const [csvData, setcsvData] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const adminToken = JSON.parse(localStorage.getItem('admin')).accessToken;
    const adminId = JSON.parse(localStorage.getItem('admin')).id;

    // dialogbox

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = async (data) => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    // dialogbox
    const hideClose = () => {
        setAlertMsg(false)
    }

    var arrData = []
    const getTransactionHistory = async () => {
        const data = {
            "adminid": adminId
        }
        const response = await AdminTrasnectionHistory(data, adminToken)

        if (response) {

            // console.log("response", response);
            response.map((x, index) => {
                return arrData.push({
                    "id": index,
                    "admindetails": x.admindetails.username,
                    "createdAt": x.createdAt,
                    "fund": x.fund,
                    "fundremain": x.fundremain,
                    "sign_charge": x.sign_charge,
                    "sign_type": x.sign_type,
                    "pan_verify": x.sign_type,
                    "fullname": x.userdetails.fullname
                })
            })
console.log("dddd" ,);

            setData(arrData);
        }


        const csv = []
        response.map((x, index) => {

            return csv.push({
                "Id": index + 1,
                "Client-Name": x.userdetails.fullname,
                "Date And Time": fDateTime(x.createdAt),
                // "Credit": x.fund_added,
                "Available-Fund": x.fund,
                "Sign-Type": x.sign_type,
                "Sign-Charge": x.sign_charge,
                "Remaining_Fund": x.fundremain,
            })
        })
        setcsvData(csv)

    }





    useEffect(() => {
        getTransactionHistory();
   


    }, [])






    const columns = [
        // {
        //     label: 'Sr .No',
        //     name: "ids",
        //     width: '150px !important',
        //     options: {
        //         customBodyRender: (value, tableMeta, rowData, updateValue) => {
        //             // return tableMeta.rowIndex + 1

        //         },

        //     }
        // },
        {
            label: 'Created At',
            name: `createdAt`,
            width: '150px !important',
            options: {
                customBodyRender: (value, tableMeta, rowData, updateValue) => {
                    return (
                        <p>
                            {
                                fDateTime(tableMeta.rowData[1])
                                // console.log("dddd" ,tableMeta.rowData)
                            }
                        </p>
                    )
                }
            },
        },
        {
            label: 'Client Name',
            name: "fullname",
            width: '150px !important',
            textAlign: "center"
        },


        {
            label: 'Verification Type',
            name: "sign_type",
            width: '170px !important',
            textAlign: "center",
        },
        {
            label: 'Charges',
            name: "sign_charge",
            width: '150px !important',
            textAlign: "center"
        },
        {
            label: 'Available Balance',
            name: "fundremain",
            width: '170px !important',
            textAlign: "center",
        },

    ];



    const handleStartDateChange = (event) => {
        const date = new Date(event.target.value);
        setStartDate(date);
    };

    const handleEndDateChange = (event) => {
        const date = new Date(event.target.value);
        setEndDate(date);
    };



    const handleFilterSubmit = (event) => {
        // event.preventDefault();

        // // filter the data based on the selected date range
        // const filtered = data.filter(item => {
        //     const itemDate = new Date(item.createdAt);
        //     console.log("itemDate", itemDate);
        //     return (
        //         (!startDate || itemDate >= startDate) &&
        //         (!endDate || itemDate <= endDate)
        //     )
        // }).filter((item) => {
        //     if (item.fullname.includes(fullname)) {
        //         return (item)
        //     }
        // })

        // // set the filtered data and the filter status
        // console.log("filtered", filtered);
        // setData(filtered);
        // // setIsFiltered(true);
    };



    const handleResetClick = () => {
        // reset the filter and the filtered data
        setrefresh(!refresh)
        setData(data);
        setStartDate(null);
        setfullname('');
        setEndDate(null);
        // setIsFiltered(false);
    };


    
    const abc = (status) => {

        const filteredData = data.filter(x => {
                  return x.fullname.toString().toLowerCase().includes(fullname.toString().toLowerCase())
        })
        
        setData(filteredData)

    }


    useEffect(() => {
        abc()
    }, [fullname])



    return (

        <>
            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Company Transaction History
                    </Typography>

                </Stack>
                <Card>
                    <div className='row'>
                        <div className='my-3 d-flex ms-3'>
                            <input type='text'
                                className="form-control ms-2"
                                placeholder='Client Name '
                              
                                name="fullname"
                                style={{                    
                                    width: '200px',
                                }}

                                onChange={(e) => { setfullname(e.target.value); }}
                            // value={fullname}
                            />


                            <Button className='MuiButton-contained mt-0 text-light ms-2' style={{
                            }}
                                onClick={() => handleResetClick()}
                            >Reset</Button>

                            <ExportToExcel
                                style={{
                                    // position: 'aboslute',
                                    right: '9px',
                                    // top: '1em',
                                    zIndex: 999,
                                }}
                                className="btn text-light export"
                                apiData={csvData && csvData}
                                fileName='Fund History'
                            />
                            {/* <div className='row'>
                        <div className='my-3 d-flex ms-3'>


                            <input type='text'
                                className="form-control ms-2"
                                placeholder='Client Name '
                                name="fullname"
                                style={{
                                    // left: '1.6rem',
                                    // position: 'absolute',
                                    // top: '1rem',
                                    width: '200px',
                                    // background: 'gray',

                                    // zIndex: 10,

                                }}

                                onChange={(e) => { handleFilterChange(e); }}
                            // value={flterClientname}
                            />

                            {/* <div className=' d-flex ms-3' style={{ marginBottom: '4rem' }}> */}

                            <ExportToExcel
                                style={{
                                    position: 'aboslute',
                                    right: '9px',
                                    top: '1em',
                                    zIndex: 999,
                                }}
                                className="btn text-light export"
                                apiData={csvData && csvData}
                                fileName='Transaction History'
                            />
                            {/* </div > */}
                        </div >
                    </div>
                    <DataTable
                        columns={columns}
                        data={data}

                    />
                </Card>


            </Container>

        </>
    )
}

export default TransecectionHistory

