import React from 'react'

const LinkExpored = () => {
  return (
    <div>
    <div className= ""  style={{height:'100vh'}} >
        <img  src="./assets/images/error.png"   style={{width:'200px'}}  className="m-auto mt-5 pt-5"/><br/>
        <h4 className='text-center'>This varification link is expired. <br/>please contact to admin</h4>
        </div>
     
    </div>
  )
}

export default LinkExpored